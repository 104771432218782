import { Box, Flex } from 'grid-styled'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import * as React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'
import Map from '../components/Map/Map'

import CTA from '../components/CTA'
import Header from '../components/Header'
import FAQs from '../components/FAQs'
import Contact from '../components/Contact'
import Hero from '../components/Hero'
import Button from '../components/Button'
import styled from 'styled-components'
import Card from '../components/Card'
import { yPad } from '../utils/theme'
import { HomeHeader } from '.'

const Content = styled.div`
  max-width: 750px;
  width: 90%;
  margin: 80px auto 50px;

  h2 {
    font-weight: 400;
    margin-bottom: 1em;
  }

  span {
    color: ${(props) => props.theme.colors.primary};
    font-size: 20px;
    display: inline-block;
    margin-bottom: 1em;
  }
`

const Details = styled.section`
  background-color: #f9f8f7;
  padding: 100px;
  h2 {
    text-align: center;
    margin-bottom: 3rem;
    i {
      color: ${(props) => props.theme.colors.primary};
      font-style: normal;
    }
  }

  section {
    display: flex;
    justify-content: space-around;

    @media (max-width: 550px) {
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 1000px) {
    padding: 100px 25px;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;
    div {
      width: 80%;
      margin-bottom: 40px;
    }
  }
  @media (max-width: 500px) {
    div {
      justify-content: center;
      align-items: center;
    }
  }

  strong {
    font-weight: 500;
    margin-right: 1.5rem;
  }

  p {
    margin: 0;
  }
`

const ContactCards = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  ${yPad};
  padding-bottom: 0 !important;

  ${Box} {
    max-width: 450px;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75%;
    background-color: #f9f8f7;

    @media (min-width: 940px) {
      height: 155px;
    }
  }

  @media (max-width: 639px) {
    flex-direction: column;
    ${Box} {
      margin: 0 auto;
    }
  }
`

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>Contact Us - QLD Sports Aviators</title>
      <meta
        name="description"
        content="Contact the Queensland Sports Aviators committee to find out how we can help you with all your sports aviation questions."
      />
    </Helmet>

    <Header>
      <h1>Contact us today</h1>
    </Header>

    <HomeHeader
      style={{
        height: 700,
        marginTop: 100,
        paddingLeft: 0,
        paddingTop: '7rem',
        paddingBottom: '5rem',
      }}
    >
      <figure>
        <Img fluid={data.bgContact.childImageSharp.fluid} />
      </figure>
      <Contact
        id="form"
        header=""
        style={{ paddingTop: '0', textAlign: 'left' }}
        noBorder={true}
      />
    </HomeHeader>
  </Layout>
)

export const query = graphql`
  query ContactPageQuery {
    bgContact: file(relativePath: { eq: "bg-contact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
